import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { transferInput } from '../inputs/transfer.input';

const form: FormInput[] = [transferInput.businessId];

export const transferBusinessForm = createForm(form);

export type TransferBusinessFormBody = {
  businessId: string;
};
