"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratableFormElement = exports.FormElement = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var FormElement = function (props) {
    return (0, jsx_runtime_1.jsx)(props.Element, {});
};
exports.FormElement = FormElement;
exports.GeneratableFormElement = {
    type: 'element',
    Element: exports.FormElement,
};
