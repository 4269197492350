import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const id: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'id',
    label: 'Vehicle:id',
    type: 'text',
  },
};

const registerNumber: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'registerNumber',
    label: 'Vehicle:registerNumber',
    type: 'text',
  },
};

const VIN: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'VIN',
    label: 'Vehicle:VIN',
    type: 'text',
  },
};

export const vehicleFilterInput = {
  id,
  registerNumber,
  VIN,
};
