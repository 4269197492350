import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { userFilterInput } from '../inputs/user-filter.input';

const filter: FormInput[] = [
  userFilterInput.name,
  userFilterInput.email,
  userFilterInput.id,
  userFilterInput.stripeCustomerId,
  userFilterInput.partnerName,
  userFilterInput.businessName,
];

export const userFilterForm = createForm(filter);

export type UserFilterFormBody = {
  name: string;
  email: string;
  id: string;
  stripeCustomerId: string;
  partnerName: string;
  businessName: string;
};
