import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSubmitHandler } from 'shared-ui/lib/customs/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from 'shared-ui/lib/customs/react/app-form-scene/AppFormScene';
import { adminOptionsForm } from '../../forms/admin-options.form';
import { useAdminOptionsQuery, useUpdateAdminOptionsMutation } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const AdminOptionsScene = (): JSX.Element => {
  const { t } = useTranslation('AdminOptionsScene');
  const context = useGQLContext();

  const query = useAdminOptionsQuery({ context });

  const [updat6eAdminOptions, { loading }] = useUpdateAdminOptionsMutation({
    onCompleted: (data) => {
      if (data.updateAdminOptions) {
        toast.success(t('Common:saved'));
      }
    },
    context,
  });

  const handleSubmit = useSubmitHandler(updat6eAdminOptions);

  return (
    <>
      <AppFormScene
        type="edit"
        query={query}
        queryDataPath="adminOptions"
        title={t('title')}
        form={adminOptionsForm}
        isSubmitLoading={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
};
