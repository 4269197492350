import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AcceptInvitationDto = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AdminBusinessType = {
  __typename?: 'AdminBusinessType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
};

export type AdminOptionsDto = {
  archiveUser: Scalars['String']['input'];
  stripePriceId: Scalars['String']['input'];
};

export type AdminOptionsType = {
  __typename?: 'AdminOptionsType';
  archiveUser: Scalars['String']['output'];
  id: Scalars['String']['output'];
  stripePriceId: Scalars['String']['output'];
};

export type AdminPartnerType = {
  __typename?: 'AdminPartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AdminUpdateUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AdminUpdateVehicleDto = {
  isArchived: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type AdminUserType = {
  __typename?: 'AdminUserType';
  business?: Maybe<AdminBusinessType>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<AdminPartnerType>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type AdminVehicleList = {
  __typename?: 'AdminVehicleList';
  list: Array<AdminVehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type AdminVehicleSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type AdminVehicleType = {
  __typename?: 'AdminVehicleType';
  VIN?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  business?: Maybe<AdminBusinessType>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  user?: Maybe<AdminUserType>;
};

export type AdminVehicleWhere = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String']['output'];
  user: UserType;
};

export type AuthStatusResponse = {
  __typename?: 'AuthStatusResponse';
  status: Scalars['String']['output'];
};

export type BusinessType = {
  __typename?: 'BusinessType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChangeUserInformationDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CompleteReminderDto = {
  mileage?: InputMaybe<Scalars['Int']['input']>;
  reminderId: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceEvents: Array<UpdateServiceEventDto>;
  vehicleId: Scalars['String']['input'];
};

export type CompleteReminderResponse = {
  __typename?: 'CompleteReminderResponse';
  count: Scalars['Int']['output'];
  ids: Array<Scalars['String']['output']>;
};

export type ConfirmDto = {
  confirmToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  vehicleId: Scalars['String']['input'];
};

export type CreateReminderDto = {
  date: Scalars['DateTime']['input'];
  faultId?: InputMaybe<Scalars['String']['input']>;
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
  vehicleId: Scalars['String']['input'];
};

export type CreateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FaultList = {
  __typename?: 'FaultList';
  list: Array<FaultType>;
  totalCount: Scalars['Int']['output'];
};

export type FaultSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type FaultType = {
  __typename?: 'FaultType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDone: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  user: UserType;
  vehicle: VehicleType;
};

export type FaultWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeedbackStatusType = {
  __typename?: 'FeedbackStatusType';
  status: Scalars['String']['output'];
};

export type ForgotPasswordDto = {
  email: Scalars['String']['input'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: Scalars['String']['output'];
};

export type InviteBusinessUserDto = {
  email: Scalars['String']['input'];
  isEmployee: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type InviteUserDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LoginDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** [Admin] Delete user */
  aDeleteUser: AdminUserType;
  /** [Admin] Update user */
  aUpdateUser: AdminUserType;
  /** [Admin] Update vehicle */
  aUpdateVehicle: AdminVehicleType;
  /** Complete user invitation */
  acceptInvitationToken: Scalars['Boolean']['output'];
  /** Update user information */
  changeUserInformation: UserType;
  /** Complete reminder */
  completeReminder: CompleteReminderResponse;
  /** Confirm user email */
  confirm: AuthStatusResponse;
  /** Create a new fault */
  createFault: FaultType;
  createReminder: ReminderType;
  /** Create service event */
  createServiceEvent: ServiceEventType;
  /** Create a transfer token for the vehicle */
  createTransferToken: Scalars['String']['output'];
  /** Create a vehicle */
  createVehicle: VehicleType;
  /** Create a voucher */
  createVoucher: VoucherType;
  /** Delete the fault */
  deleteFault: FaultType;
  deleteReminder: ReminderType;
  /** Delete service event */
  deleteServiceEvent: ServiceEventType;
  /** Delete user */
  deleteUser: UserType;
  /** Delete a vehicle */
  deleteVehicle: VehicleType;
  /** Send forgot password link email to user */
  forgotPasswordConsumer: ForgotPasswordResponse;
  /** Send forgot password link email to user */
  forgotPasswordEmployee: ForgotPasswordResponse;
  /** Send forgot password link email to user */
  forgotPasswordPartner: ForgotPasswordResponse;
  /** Invite a user to the business */
  inviteBusinessUser: UserType;
  /** Invite partner user */
  invitePartnerUser: UserType;
  /** Login a consumer user */
  loginClient: AuthResponse;
  /** Login a business user */
  loginEmployee: AuthResponse;
  /** Login a partner user */
  loginPartner: AuthResponse;
  /** Mark the fault as read */
  markAsRead: Scalars['Boolean']['output'];
  /** Register a new business user account */
  registerBusinessUser: AuthResponse;
  /** Register a new consumer user account */
  registerConsumerUser: AuthResponse;
  /** Register a new partner user account */
  registerPartnerUser: AuthResponse;
  /** Account deletion request */
  requestAccountDelete: Scalars['Boolean']['output'];
  /** Resend confirmation email */
  resendConfirmation: AuthStatusResponse;
  /** Reset password with token */
  resetPassword: ResetPasswordResponse;
  /** Send feedback to the team */
  sendFeedback: FeedbackStatusType;
  /** Transfer the vehicle to the user */
  transferVehicle: TransferVehicleType;
  /** [Admin] Transfer vehicle to business account */
  transferVehicleToBusiness: AdminVehicleType;
  /** [Admin] Transfer vehicle to user account */
  transferVehicleToConsumer: AdminVehicleType;
  /** Update admin options */
  updateAdminOptions: AdminOptionsType;
  /** Update the business details of the current user */
  updateBusiness: BusinessType;
  /** Update the fault details */
  updateFault: FaultType;
  /** Mark the fault as done */
  updateFaultAsDone: FaultType;
  /** Update partner details by user */
  updatePartner: PartnerType;
  updateReminder: ReminderType;
  /** Update service event */
  updateServiceEvent: ServiceEventType;
  /** Update a vehicle */
  updateVehicle: VehicleType;
  /** Update a voucher */
  updateVoucher: VoucherType;
  /** Use a voucher */
  useVoucher: VoucherLogType;
  /** Verify if the invitation token is valid */
  verifyAcceptInvitationToken: Scalars['Boolean']['output'];
};


export type MutationADeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationAUpdateUserArgs = {
  body: AdminUpdateUserDto;
  id: Scalars['String']['input'];
};


export type MutationAUpdateVehicleArgs = {
  body: AdminUpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationAcceptInvitationTokenArgs = {
  body: AcceptInvitationDto;
};


export type MutationChangeUserInformationArgs = {
  body: ChangeUserInformationDto;
};


export type MutationCompleteReminderArgs = {
  body: CompleteReminderDto;
};


export type MutationConfirmArgs = {
  body: ConfirmDto;
};


export type MutationCreateFaultArgs = {
  body: CreateFaultDto;
};


export type MutationCreateReminderArgs = {
  body: CreateReminderDto;
};


export type MutationCreateServiceEventArgs = {
  body: CreateServiceEventDto;
};


export type MutationCreateTransferTokenArgs = {
  vehicleId: Scalars['String']['input'];
};


export type MutationCreateVehicleArgs = {
  body: CreateVehicleDto;
};


export type MutationCreateVoucherArgs = {
  body: VoucherDto;
};


export type MutationDeleteFaultArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteReminderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['String']['input'];
};


export type MutationForgotPasswordConsumerArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordEmployeeArgs = {
  body: ForgotPasswordDto;
};


export type MutationForgotPasswordPartnerArgs = {
  body: ForgotPasswordDto;
};


export type MutationInviteBusinessUserArgs = {
  body: InviteBusinessUserDto;
};


export type MutationInvitePartnerUserArgs = {
  body: InviteUserDto;
};


export type MutationLoginClientArgs = {
  body: LoginDto;
};


export type MutationLoginEmployeeArgs = {
  body: LoginDto;
};


export type MutationLoginPartnerArgs = {
  body: LoginDto;
};


export type MutationMarkAsReadArgs = {
  id: Scalars['String']['input'];
};


export type MutationRegisterBusinessUserArgs = {
  body: RegisterBusinessUserDto;
};


export type MutationRegisterConsumerUserArgs = {
  body: RegisterConsumerUserDto;
};


export type MutationRegisterPartnerUserArgs = {
  body: RegisterPartnerUserDto;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordDto;
};


export type MutationSendFeedbackArgs = {
  message: Scalars['String']['input'];
};


export type MutationTransferVehicleArgs = {
  token: Scalars['String']['input'];
};


export type MutationTransferVehicleToBusinessArgs = {
  businessId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationTransferVehicleToConsumerArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateAdminOptionsArgs = {
  body: AdminOptionsDto;
};


export type MutationUpdateBusinessArgs = {
  body: UpdateBusinessDto;
};


export type MutationUpdateFaultArgs = {
  body: UpdateFaultDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateFaultAsDoneArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdatePartnerArgs = {
  body: UpdatePartnerDto;
};


export type MutationUpdateReminderArgs = {
  body: UpdateReminderDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateServiceEventArgs = {
  body: UpdateServiceEventDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVehicleArgs = {
  body: UpdateVehicleDto;
  id: Scalars['String']['input'];
};


export type MutationUpdateVoucherArgs = {
  body: VoucherDto;
  id: Scalars['String']['input'];
};


export type MutationUseVoucherArgs = {
  vehicleId: Scalars['String']['input'];
  voucher: Scalars['String']['input'];
};


export type MutationVerifyAcceptInvitationTokenArgs = {
  body: VerifyAcceptInvitationDto;
};

export type PartnerType = {
  __typename?: 'PartnerType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** [Admin] Get business */
  aBusiness: AdminBusinessType;
  /** [Admin] Get user */
  aUser: AdminUserType;
  /** [Admin] Get users */
  aUsers: UserList;
  /** [Admin] Get a vehicle */
  aVehicle: AdminVehicleType;
  /** [Admin] Get vehicles */
  aVehicles: AdminVehicleList;
  /** Get admin options */
  adminOptions: AdminOptionsType;
  /** Get the business details of the current user */
  business: BusinessType;
  /** Create a new checkout session */
  createCheckoutSession: StripeCheckoutType;
  /** [Admin] Export users as CSV */
  exportCSV: Scalars['String']['output'];
  /** Get the fault details */
  fault: FaultType;
  /** Get the list of faults */
  faults: FaultList;
  /** Get partner details by user */
  partner: PartnerType;
  reminder: ReminderType;
  reminders: ReminderList;
  /** [Admin] Request admin login token */
  requestAdminToken: AuthStatusResponse;
  /** Search a vehicle by token */
  searchVehicle: VehicleType;
  /** Get service event */
  serviceEvent: ServiceEventType;
  /** Get all users */
  users: UserList;
  /** [Admin] Validate admin token */
  validateAdminToken: AuthStatusResponse;
  /** Validate reset password token */
  validateResetToken: ValidateResetTokenResponse;
  /** Validate the transfer token */
  validateTransferToken: ValidateTransferTokenType;
  /** Get a vehicle */
  vehicle: VehicleType;
  /** Get a list of vehicles */
  vehicles: VehicleList;
  /** Get a voucher */
  voucher: VoucherType;
  /** Get a list of vouchers */
  vouchers: VoucherList;
  /** Get user information */
  whoAmI: UserType;
};


export type QueryABusinessArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryAUsersArgs = {
  orderBy?: InputMaybe<Array<UserSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhere>;
};


export type QueryAVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryAVehiclesArgs = {
  orderBy?: InputMaybe<Array<AdminVehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminVehicleWhere>;
};


export type QueryCreateCheckoutSessionArgs = {
  vehicleId: Scalars['String']['input'];
};


export type QueryFaultArgs = {
  id: Scalars['String']['input'];
};


export type QueryFaultsArgs = {
  orderBy?: InputMaybe<Array<FaultSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FaultWhere>;
};


export type QueryReminderArgs = {
  id: Scalars['String']['input'];
};


export type QueryRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type QuerySearchVehicleArgs = {
  token: Scalars['String']['input'];
};


export type QueryServiceEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryValidateResetTokenArgs = {
  body: ValidateResetTokenDto;
};


export type QueryValidateTransferTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  id: Scalars['String']['input'];
};


export type QueryVehiclesArgs = {
  orderBy?: InputMaybe<Array<VehicleSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhere>;
};


export type QueryVoucherArgs = {
  id: Scalars['String']['input'];
};


export type QueryVouchersArgs = {
  orderBy?: InputMaybe<Array<VoucherSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhere>;
};

export type RegisterBusinessUserDto = {
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterConsumerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegisterPartnerUserDto = {
  email: Scalars['String']['input'];
  isAccepted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  partnerName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ReminderList = {
  __typename?: 'ReminderList';
  list: Array<ReminderType>;
  totalCount: Scalars['Int']['output'];
};

export type ReminderSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderType = {
  __typename?: 'ReminderType';
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isNotificationable?: Maybe<Scalars['Boolean']['output']>;
  isPartnerLocked: Scalars['Boolean']['output'];
  notificationDaysBefore?: Maybe<Scalars['Float']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ReminderWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ResetPasswordDto = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String']['output'];
};

export type ServiceEventSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceEventType = {
  __typename?: 'ServiceEventType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isLocked: Scalars['Boolean']['output'];
  isPartnerLocked: Scalars['Boolean']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  partnerId: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicle: VehicleType;
  vehicleId: Scalars['String']['output'];
};

export type ServiceEventWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StripeCheckoutType = {
  __typename?: 'StripeCheckoutType';
  url: Scalars['String']['output'];
};

export type TransferVehicleType = {
  __typename?: 'TransferVehicleType';
  vehicleId: Scalars['String']['output'];
};

export type UpdateBusinessDto = {
  name: Scalars['String']['input'];
};

export type UpdateFaultDto = {
  description: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerDto = {
  name: Scalars['String']['input'];
};

export type UpdateReminderDto = {
  date: Scalars['DateTime']['input'];
  isNotificationable?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateServiceEventDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  serviceDate: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
};

export type UpdateVehicleDto = {
  VIN: Scalars['String']['input'];
  brand?: InputMaybe<Scalars['String']['input']>;
  commissioning?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  registerNumber?: InputMaybe<Scalars['String']['input']>;
  runningHours?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<AdminUserType>;
  totalCount: Scalars['Int']['output'];
};

export type UserSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UserType = {
  __typename?: 'UserType';
  business?: Maybe<BusinessType>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBusinessAccountOwner: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPartnerAccountOwner: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner?: Maybe<PartnerType>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserWhere = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ValidateResetTokenDto = {
  resetPasswordToken: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type ValidateResetTokenResponse = {
  __typename?: 'ValidateResetTokenResponse';
  status: Scalars['Boolean']['output'];
};

export type ValidateTransferTokenType = {
  __typename?: 'ValidateTransferTokenType';
  isVehicleOwner: Scalars['Boolean']['output'];
};

export type VehicleList = {
  __typename?: 'VehicleList';
  list: Array<VehicleType>;
  totalCount: Scalars['Int']['output'];
};

export type VehicleSort = {
  VIN?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  VIN: Scalars['String']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  commissioning?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasActiveLicense: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  licenseExpireDate: Scalars['DateTime']['output'];
  licenseStatus: Scalars['String']['output'];
  mileage?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  reminders: Array<ReminderType>;
  runningHours?: Maybe<Scalars['Int']['output']>;
  serviceEvents: Array<ServiceEventType>;
  token: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
  warranty?: Maybe<Scalars['DateTime']['output']>;
};


export type VehicleTypeRemindersArgs = {
  orderBy?: InputMaybe<Array<ReminderSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReminderWhere>;
};


export type VehicleTypeServiceEventsArgs = {
  orderBy?: InputMaybe<Array<ServiceEventSort>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceEventWhere>;
};

export type VehicleWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VerifyAcceptInvitationDto = {
  inviteToken: Scalars['String']['input'];
};

export type VoucherDto = {
  endDate: Scalars['DateTime']['input'];
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  useLimit: Scalars['Int']['input'];
};

export type VoucherList = {
  __typename?: 'VoucherList';
  list: Array<VoucherType>;
  totalCount: Scalars['Int']['output'];
};

export type VoucherLogType = {
  __typename?: 'VoucherLogType';
  id: Scalars['String']['output'];
};

export type VoucherSort = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherType = {
  __typename?: 'VoucherType';
  createdAt: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useLimit: Scalars['Int']['output'];
  uses: Scalars['Int']['output'];
};

export type VoucherWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AdminOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminOptionsQuery = { __typename?: 'Query', adminOptions: { __typename?: 'AdminOptionsType', stripePriceId: string, archiveUser: string } };

export type PreviewTransferConsumerQueryVariables = Exact<{
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type PreviewTransferConsumerQuery = { __typename?: 'Query', aVehicle: { __typename?: 'AdminVehicleType', registerNumber?: string | null, VIN?: string | null }, aUser: { __typename?: 'AdminUserType', id: string, name: string } };

export type PreviewTransferBusinessQueryVariables = Exact<{
  id: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
}>;


export type PreviewTransferBusinessQuery = { __typename?: 'Query', aVehicle: { __typename?: 'AdminVehicleType', registerNumber?: string | null, VIN?: string | null }, aBusiness: { __typename?: 'AdminBusinessType', id: string, name: string } };

export type CreateVoucherMutationVariables = Exact<{
  body: VoucherDto;
}>;


export type CreateVoucherMutation = { __typename?: 'Mutation', createVoucher: { __typename?: 'VoucherType', id: string } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', aDeleteUser: { __typename?: 'AdminUserType', id: string } };

export type ExportUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportUsersQuery = { __typename?: 'Query', exportCSV: string };

export type LoginQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginQuery = { __typename?: 'Query', requestAdminToken: { __typename?: 'AuthStatusResponse', status: string } };

export type TransferVehicleToBusinessMutationVariables = Exact<{
  id: Scalars['String']['input'];
  businessId: Scalars['String']['input'];
}>;


export type TransferVehicleToBusinessMutation = { __typename?: 'Mutation', transferVehicleToBusiness: { __typename?: 'AdminVehicleType', id: string } };

export type TransferVehicleToConsumerMutationVariables = Exact<{
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type TransferVehicleToConsumerMutation = { __typename?: 'Mutation', transferVehicleToConsumer: { __typename?: 'AdminVehicleType', id: string } };

export type UpdateAdminOptionsMutationVariables = Exact<{
  body: AdminOptionsDto;
}>;


export type UpdateAdminOptionsMutation = { __typename?: 'Mutation', updateAdminOptions: { __typename?: 'AdminOptionsType', id: string } };

export type UpdateUserMutationVariables = Exact<{
  body: AdminUpdateUserDto;
  id: Scalars['String']['input'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', aUpdateUser: { __typename?: 'AdminUserType', id: string } };

export type UpdateVehicleMutationVariables = Exact<{
  body: AdminUpdateVehicleDto;
  id: Scalars['String']['input'];
}>;


export type UpdateVehicleMutation = { __typename?: 'Mutation', aUpdateVehicle: { __typename?: 'AdminVehicleType', id: string } };

export type UpdateVoucherMutationVariables = Exact<{
  body: VoucherDto;
  id: Scalars['String']['input'];
}>;


export type UpdateVoucherMutation = { __typename?: 'Mutation', updateVoucher: { __typename?: 'VoucherType', endDate: any, id: string, isActive: boolean, name: string, useLimit: number } };

export type UserEditSceneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type UserEditSceneQuery = { __typename?: 'Query', aUser: { __typename?: 'AdminUserType', name: string, email: string, stripeCustomerId?: string | null, isBusinessAccountOwner: boolean, business?: { __typename?: 'AdminBusinessType', id: string, stripeCustomerId?: string | null } | null } };

export type UserIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<UserSort> | UserSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhere>;
}>;


export type UserIndexSceneQuery = { __typename?: 'Query', aUsers: { __typename?: 'UserList', totalCount: number, list: Array<{ __typename?: 'AdminUserType', id: string, name: string, email: string, type: string, isBusinessAccountOwner: boolean, isPartnerAccountOwner: boolean, partner?: { __typename?: 'AdminPartnerType', id: string, name: string } | null, business?: { __typename?: 'AdminBusinessType', id: string, name: string } | null }> } };

export type VehicleEditSceneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VehicleEditSceneQuery = { __typename?: 'Query', aVehicle: { __typename?: 'AdminVehicleType', token?: string | null, isArchived: boolean, registerNumber?: string | null, VIN?: string | null } };

export type VehicleIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<AdminVehicleSort> | AdminVehicleSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdminVehicleWhere>;
}>;


export type VehicleIndexSceneQuery = { __typename?: 'Query', aVehicles: { __typename?: 'AdminVehicleList', totalCount: number, list: Array<{ __typename?: 'AdminVehicleType', id: string, isArchived: boolean, name?: string | null, type: string, registerNumber?: string | null, VIN?: string | null, model?: string | null, token?: string | null, modelYear?: number | null, brand?: string | null, user?: { __typename?: 'AdminUserType', name: string } | null, business?: { __typename?: 'AdminBusinessType', name: string } | null }> } };

export type VoucherEditSceneQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VoucherEditSceneQuery = { __typename?: 'Query', voucher: { __typename?: 'VoucherType', endDate: any, isActive: boolean, name: string, useLimit: number } };

export type VoucherIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<VoucherSort> | VoucherSort>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VoucherWhere>;
}>;


export type VoucherIndexSceneQuery = { __typename?: 'Query', vouchers: { __typename?: 'VoucherList', totalCount: number, list: Array<{ __typename?: 'VoucherType', createdAt: any, endDate: any, id: string, isActive: boolean, name: string, useLimit: number, uses: number }> } };


export const AdminOptionsDocument = gql`
    query AdminOptions {
  adminOptions {
    stripePriceId
    archiveUser
  }
}
    `;

/**
 * __useAdminOptionsQuery__
 *
 * To run a query within a React component, call `useAdminOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminOptionsQuery, AdminOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOptionsQuery, AdminOptionsQueryVariables>(AdminOptionsDocument, options);
      }
export function useAdminOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOptionsQuery, AdminOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOptionsQuery, AdminOptionsQueryVariables>(AdminOptionsDocument, options);
        }
export function useAdminOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminOptionsQuery, AdminOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminOptionsQuery, AdminOptionsQueryVariables>(AdminOptionsDocument, options);
        }
export type AdminOptionsQueryHookResult = ReturnType<typeof useAdminOptionsQuery>;
export type AdminOptionsLazyQueryHookResult = ReturnType<typeof useAdminOptionsLazyQuery>;
export type AdminOptionsSuspenseQueryHookResult = ReturnType<typeof useAdminOptionsSuspenseQuery>;
export type AdminOptionsQueryResult = Apollo.QueryResult<AdminOptionsQuery, AdminOptionsQueryVariables>;
export const PreviewTransferConsumerDocument = gql`
    query PreviewTransferConsumer($id: String!, $userId: String!) {
  aVehicle(id: $id) {
    registerNumber
    VIN
  }
  aUser(id: $userId) {
    id
    name
  }
}
    `;

/**
 * __usePreviewTransferConsumerQuery__
 *
 * To run a query within a React component, call `usePreviewTransferConsumerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewTransferConsumerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewTransferConsumerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePreviewTransferConsumerQuery(baseOptions: Apollo.QueryHookOptions<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables> & ({ variables: PreviewTransferConsumerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables>(PreviewTransferConsumerDocument, options);
      }
export function usePreviewTransferConsumerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables>(PreviewTransferConsumerDocument, options);
        }
export function usePreviewTransferConsumerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables>(PreviewTransferConsumerDocument, options);
        }
export type PreviewTransferConsumerQueryHookResult = ReturnType<typeof usePreviewTransferConsumerQuery>;
export type PreviewTransferConsumerLazyQueryHookResult = ReturnType<typeof usePreviewTransferConsumerLazyQuery>;
export type PreviewTransferConsumerSuspenseQueryHookResult = ReturnType<typeof usePreviewTransferConsumerSuspenseQuery>;
export type PreviewTransferConsumerQueryResult = Apollo.QueryResult<PreviewTransferConsumerQuery, PreviewTransferConsumerQueryVariables>;
export const PreviewTransferBusinessDocument = gql`
    query PreviewTransferBusiness($id: String!, $businessId: String!) {
  aVehicle(id: $id) {
    registerNumber
    VIN
  }
  aBusiness(id: $businessId) {
    id
    name
  }
}
    `;

/**
 * __usePreviewTransferBusinessQuery__
 *
 * To run a query within a React component, call `usePreviewTransferBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewTransferBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewTransferBusinessQuery({
 *   variables: {
 *      id: // value for 'id'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function usePreviewTransferBusinessQuery(baseOptions: Apollo.QueryHookOptions<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables> & ({ variables: PreviewTransferBusinessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables>(PreviewTransferBusinessDocument, options);
      }
export function usePreviewTransferBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables>(PreviewTransferBusinessDocument, options);
        }
export function usePreviewTransferBusinessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables>(PreviewTransferBusinessDocument, options);
        }
export type PreviewTransferBusinessQueryHookResult = ReturnType<typeof usePreviewTransferBusinessQuery>;
export type PreviewTransferBusinessLazyQueryHookResult = ReturnType<typeof usePreviewTransferBusinessLazyQuery>;
export type PreviewTransferBusinessSuspenseQueryHookResult = ReturnType<typeof usePreviewTransferBusinessSuspenseQuery>;
export type PreviewTransferBusinessQueryResult = Apollo.QueryResult<PreviewTransferBusinessQuery, PreviewTransferBusinessQueryVariables>;
export const CreateVoucherDocument = gql`
    mutation CreateVoucher($body: VoucherDTO!) {
  createVoucher(body: $body) {
    id
  }
}
    `;
export type CreateVoucherMutationFn = Apollo.MutationFunction<CreateVoucherMutation, CreateVoucherMutationVariables>;

/**
 * __useCreateVoucherMutation__
 *
 * To run a mutation, you first call `useCreateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoucherMutation, { data, loading, error }] = useCreateVoucherMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateVoucherMutation(baseOptions?: Apollo.MutationHookOptions<CreateVoucherMutation, CreateVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVoucherMutation, CreateVoucherMutationVariables>(CreateVoucherDocument, options);
      }
export type CreateVoucherMutationHookResult = ReturnType<typeof useCreateVoucherMutation>;
export type CreateVoucherMutationResult = Apollo.MutationResult<CreateVoucherMutation>;
export type CreateVoucherMutationOptions = Apollo.BaseMutationOptions<CreateVoucherMutation, CreateVoucherMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  aDeleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ExportUsersDocument = gql`
    query ExportUsers {
  exportCSV
}
    `;

/**
 * __useExportUsersQuery__
 *
 * To run a query within a React component, call `useExportUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportUsersQuery(baseOptions?: Apollo.QueryHookOptions<ExportUsersQuery, ExportUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportUsersQuery, ExportUsersQueryVariables>(ExportUsersDocument, options);
      }
export function useExportUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportUsersQuery, ExportUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportUsersQuery, ExportUsersQueryVariables>(ExportUsersDocument, options);
        }
export function useExportUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportUsersQuery, ExportUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportUsersQuery, ExportUsersQueryVariables>(ExportUsersDocument, options);
        }
export type ExportUsersQueryHookResult = ReturnType<typeof useExportUsersQuery>;
export type ExportUsersLazyQueryHookResult = ReturnType<typeof useExportUsersLazyQuery>;
export type ExportUsersSuspenseQueryHookResult = ReturnType<typeof useExportUsersSuspenseQuery>;
export type ExportUsersQueryResult = Apollo.QueryResult<ExportUsersQuery, ExportUsersQueryVariables>;
export const LoginDocument = gql`
    query Login {
  requestAdminToken {
    status
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginQuery(baseOptions?: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export function useLoginSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginSuspenseQueryHookResult = ReturnType<typeof useLoginSuspenseQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const TransferVehicleToBusinessDocument = gql`
    mutation TransferVehicleToBusiness($id: String!, $businessId: String!) {
  transferVehicleToBusiness(id: $id, businessId: $businessId) {
    id
  }
}
    `;
export type TransferVehicleToBusinessMutationFn = Apollo.MutationFunction<TransferVehicleToBusinessMutation, TransferVehicleToBusinessMutationVariables>;

/**
 * __useTransferVehicleToBusinessMutation__
 *
 * To run a mutation, you first call `useTransferVehicleToBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferVehicleToBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferVehicleToBusinessMutation, { data, loading, error }] = useTransferVehicleToBusinessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      businessId: // value for 'businessId'
 *   },
 * });
 */
export function useTransferVehicleToBusinessMutation(baseOptions?: Apollo.MutationHookOptions<TransferVehicleToBusinessMutation, TransferVehicleToBusinessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferVehicleToBusinessMutation, TransferVehicleToBusinessMutationVariables>(TransferVehicleToBusinessDocument, options);
      }
export type TransferVehicleToBusinessMutationHookResult = ReturnType<typeof useTransferVehicleToBusinessMutation>;
export type TransferVehicleToBusinessMutationResult = Apollo.MutationResult<TransferVehicleToBusinessMutation>;
export type TransferVehicleToBusinessMutationOptions = Apollo.BaseMutationOptions<TransferVehicleToBusinessMutation, TransferVehicleToBusinessMutationVariables>;
export const TransferVehicleToConsumerDocument = gql`
    mutation TransferVehicleToConsumer($id: String!, $userId: String!) {
  transferVehicleToConsumer(id: $id, userId: $userId) {
    id
  }
}
    `;
export type TransferVehicleToConsumerMutationFn = Apollo.MutationFunction<TransferVehicleToConsumerMutation, TransferVehicleToConsumerMutationVariables>;

/**
 * __useTransferVehicleToConsumerMutation__
 *
 * To run a mutation, you first call `useTransferVehicleToConsumerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferVehicleToConsumerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferVehicleToConsumerMutation, { data, loading, error }] = useTransferVehicleToConsumerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTransferVehicleToConsumerMutation(baseOptions?: Apollo.MutationHookOptions<TransferVehicleToConsumerMutation, TransferVehicleToConsumerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferVehicleToConsumerMutation, TransferVehicleToConsumerMutationVariables>(TransferVehicleToConsumerDocument, options);
      }
export type TransferVehicleToConsumerMutationHookResult = ReturnType<typeof useTransferVehicleToConsumerMutation>;
export type TransferVehicleToConsumerMutationResult = Apollo.MutationResult<TransferVehicleToConsumerMutation>;
export type TransferVehicleToConsumerMutationOptions = Apollo.BaseMutationOptions<TransferVehicleToConsumerMutation, TransferVehicleToConsumerMutationVariables>;
export const UpdateAdminOptionsDocument = gql`
    mutation UpdateAdminOptions($body: AdminOptionsDTO!) {
  updateAdminOptions(body: $body) {
    id
  }
}
    `;
export type UpdateAdminOptionsMutationFn = Apollo.MutationFunction<UpdateAdminOptionsMutation, UpdateAdminOptionsMutationVariables>;

/**
 * __useUpdateAdminOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateAdminOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminOptionsMutation, { data, loading, error }] = useUpdateAdminOptionsMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateAdminOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminOptionsMutation, UpdateAdminOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminOptionsMutation, UpdateAdminOptionsMutationVariables>(UpdateAdminOptionsDocument, options);
      }
export type UpdateAdminOptionsMutationHookResult = ReturnType<typeof useUpdateAdminOptionsMutation>;
export type UpdateAdminOptionsMutationResult = Apollo.MutationResult<UpdateAdminOptionsMutation>;
export type UpdateAdminOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateAdminOptionsMutation, UpdateAdminOptionsMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($body: AdminUpdateUserDTO!, $id: String!) {
  aUpdateUser(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($body: AdminUpdateVehicleDTO!, $id: String!) {
  aUpdateVehicle(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const UpdateVoucherDocument = gql`
    mutation UpdateVoucher($body: VoucherDTO!, $id: String!) {
  updateVoucher(body: $body, id: $id) {
    endDate
    id
    isActive
    name
    useLimit
  }
}
    `;
export type UpdateVoucherMutationFn = Apollo.MutationFunction<UpdateVoucherMutation, UpdateVoucherMutationVariables>;

/**
 * __useUpdateVoucherMutation__
 *
 * To run a mutation, you first call `useUpdateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoucherMutation, { data, loading, error }] = useUpdateVoucherMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateVoucherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVoucherMutation, UpdateVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVoucherMutation, UpdateVoucherMutationVariables>(UpdateVoucherDocument, options);
      }
export type UpdateVoucherMutationHookResult = ReturnType<typeof useUpdateVoucherMutation>;
export type UpdateVoucherMutationResult = Apollo.MutationResult<UpdateVoucherMutation>;
export type UpdateVoucherMutationOptions = Apollo.BaseMutationOptions<UpdateVoucherMutation, UpdateVoucherMutationVariables>;
export const UserEditSceneDocument = gql`
    query UserEditScene($id: String!) {
  aUser(id: $id) {
    name
    email
    stripeCustomerId
    isBusinessAccountOwner
    business {
      id
      stripeCustomerId
    }
  }
}
    `;

/**
 * __useUserEditSceneQuery__
 *
 * To run a query within a React component, call `useUserEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEditSceneQuery(baseOptions: Apollo.QueryHookOptions<UserEditSceneQuery, UserEditSceneQueryVariables> & ({ variables: UserEditSceneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditSceneQuery, UserEditSceneQueryVariables>(UserEditSceneDocument, options);
      }
export function useUserEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditSceneQuery, UserEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditSceneQuery, UserEditSceneQueryVariables>(UserEditSceneDocument, options);
        }
export function useUserEditSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserEditSceneQuery, UserEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEditSceneQuery, UserEditSceneQueryVariables>(UserEditSceneDocument, options);
        }
export type UserEditSceneQueryHookResult = ReturnType<typeof useUserEditSceneQuery>;
export type UserEditSceneLazyQueryHookResult = ReturnType<typeof useUserEditSceneLazyQuery>;
export type UserEditSceneSuspenseQueryHookResult = ReturnType<typeof useUserEditSceneSuspenseQuery>;
export type UserEditSceneQueryResult = Apollo.QueryResult<UserEditSceneQuery, UserEditSceneQueryVariables>;
export const UserIndexSceneDocument = gql`
    query UserIndexScene($orderBy: [UserSort!], $skip: Int, $take: Int, $where: UserWhere) {
  aUsers(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      name
      email
      type
      isBusinessAccountOwner
      isPartnerAccountOwner
      partner {
        id
        name
      }
      business {
        id
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useUserIndexSceneQuery__
 *
 * To run a query within a React component, call `useUserIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
      }
export function useUserIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
        }
export function useUserIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
        }
export type UserIndexSceneQueryHookResult = ReturnType<typeof useUserIndexSceneQuery>;
export type UserIndexSceneLazyQueryHookResult = ReturnType<typeof useUserIndexSceneLazyQuery>;
export type UserIndexSceneSuspenseQueryHookResult = ReturnType<typeof useUserIndexSceneSuspenseQuery>;
export type UserIndexSceneQueryResult = Apollo.QueryResult<UserIndexSceneQuery, UserIndexSceneQueryVariables>;
export const VehicleEditSceneDocument = gql`
    query VehicleEditScene($id: String!) {
  aVehicle(id: $id) {
    token
    isArchived
    registerNumber
    VIN
  }
}
    `;

/**
 * __useVehicleEditSceneQuery__
 *
 * To run a query within a React component, call `useVehicleEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleEditSceneQuery(baseOptions: Apollo.QueryHookOptions<VehicleEditSceneQuery, VehicleEditSceneQueryVariables> & ({ variables: VehicleEditSceneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleEditSceneQuery, VehicleEditSceneQueryVariables>(VehicleEditSceneDocument, options);
      }
export function useVehicleEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleEditSceneQuery, VehicleEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleEditSceneQuery, VehicleEditSceneQueryVariables>(VehicleEditSceneDocument, options);
        }
export function useVehicleEditSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleEditSceneQuery, VehicleEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleEditSceneQuery, VehicleEditSceneQueryVariables>(VehicleEditSceneDocument, options);
        }
export type VehicleEditSceneQueryHookResult = ReturnType<typeof useVehicleEditSceneQuery>;
export type VehicleEditSceneLazyQueryHookResult = ReturnType<typeof useVehicleEditSceneLazyQuery>;
export type VehicleEditSceneSuspenseQueryHookResult = ReturnType<typeof useVehicleEditSceneSuspenseQuery>;
export type VehicleEditSceneQueryResult = Apollo.QueryResult<VehicleEditSceneQuery, VehicleEditSceneQueryVariables>;
export const VehicleIndexSceneDocument = gql`
    query VehicleIndexScene($orderBy: [AdminVehicleSort!], $skip: Int, $take: Int, $where: AdminVehicleWhere) {
  aVehicles(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      isArchived
      name
      type
      registerNumber
      VIN
      model
      token
      user {
        name
      }
      business {
        name
      }
      modelYear
      brand
    }
    totalCount
  }
}
    `;

/**
 * __useVehicleIndexSceneQuery__
 *
 * To run a query within a React component, call `useVehicleIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehicleIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>(VehicleIndexSceneDocument, options);
      }
export function useVehicleIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>(VehicleIndexSceneDocument, options);
        }
export function useVehicleIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>(VehicleIndexSceneDocument, options);
        }
export type VehicleIndexSceneQueryHookResult = ReturnType<typeof useVehicleIndexSceneQuery>;
export type VehicleIndexSceneLazyQueryHookResult = ReturnType<typeof useVehicleIndexSceneLazyQuery>;
export type VehicleIndexSceneSuspenseQueryHookResult = ReturnType<typeof useVehicleIndexSceneSuspenseQuery>;
export type VehicleIndexSceneQueryResult = Apollo.QueryResult<VehicleIndexSceneQuery, VehicleIndexSceneQueryVariables>;
export const VoucherEditSceneDocument = gql`
    query VoucherEditScene($id: String!) {
  voucher(id: $id) {
    endDate
    isActive
    name
    useLimit
  }
}
    `;

/**
 * __useVoucherEditSceneQuery__
 *
 * To run a query within a React component, call `useVoucherEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVoucherEditSceneQuery(baseOptions: Apollo.QueryHookOptions<VoucherEditSceneQuery, VoucherEditSceneQueryVariables> & ({ variables: VoucherEditSceneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VoucherEditSceneQuery, VoucherEditSceneQueryVariables>(VoucherEditSceneDocument, options);
      }
export function useVoucherEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VoucherEditSceneQuery, VoucherEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VoucherEditSceneQuery, VoucherEditSceneQueryVariables>(VoucherEditSceneDocument, options);
        }
export function useVoucherEditSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VoucherEditSceneQuery, VoucherEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VoucherEditSceneQuery, VoucherEditSceneQueryVariables>(VoucherEditSceneDocument, options);
        }
export type VoucherEditSceneQueryHookResult = ReturnType<typeof useVoucherEditSceneQuery>;
export type VoucherEditSceneLazyQueryHookResult = ReturnType<typeof useVoucherEditSceneLazyQuery>;
export type VoucherEditSceneSuspenseQueryHookResult = ReturnType<typeof useVoucherEditSceneSuspenseQuery>;
export type VoucherEditSceneQueryResult = Apollo.QueryResult<VoucherEditSceneQuery, VoucherEditSceneQueryVariables>;
export const VoucherIndexSceneDocument = gql`
    query VoucherIndexScene($orderBy: [VoucherSort!], $skip: Int, $take: Int, $where: VoucherWhere) {
  vouchers(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      createdAt
      endDate
      id
      isActive
      name
      useLimit
      uses
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useVoucherIndexSceneQuery__
 *
 * To run a query within a React component, call `useVoucherIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVoucherIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>(VoucherIndexSceneDocument, options);
      }
export function useVoucherIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>(VoucherIndexSceneDocument, options);
        }
export function useVoucherIndexSceneSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>(VoucherIndexSceneDocument, options);
        }
export type VoucherIndexSceneQueryHookResult = ReturnType<typeof useVoucherIndexSceneQuery>;
export type VoucherIndexSceneLazyQueryHookResult = ReturnType<typeof useVoucherIndexSceneLazyQuery>;
export type VoucherIndexSceneSuspenseQueryHookResult = ReturnType<typeof useVoucherIndexSceneSuspenseQuery>;
export type VoucherIndexSceneQueryResult = Apollo.QueryResult<VoucherIndexSceneQuery, VoucherIndexSceneQueryVariables>;