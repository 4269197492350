import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'name',
    label: 'User:name',
    type: 'text',
  },
};

const id: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'id',
    label: 'User:id',
    type: 'text',
  },
};

const stripeCustomerId: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'stripeCustomerId',
    label: 'User:stripeCustomerId',
    type: 'text',
  },
};

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'text',
  },
};

const partnerName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'partnerName',
    label: 'User:partnerNameFilter',
    type: 'text',
  },
};

const businessName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().optional(),
  props: {
    name: 'businessName',
    label: 'User:businessNameFilter',
    type: 'text',
  },
};

export const userFilterInput = {
  name,
  email,
  id,
  stripeCustomerId,
  businessName,
  partnerName,
};
