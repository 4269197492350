import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { vehicleInput } from '../inputs/vehicle.input';

const editForm: FormInput[] = [vehicleInput.token, vehicleInput.isArchived];

export const vehicleEditForm = createForm(editForm);

export type VehicleFormBody = {
  token: string;
  isArchived: boolean;
};
