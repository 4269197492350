"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
var react_1 = require("react");
var get_pagination_1 = require("./get-pagination");
var usePagination = function (options) {
    var initCurrentPageIndex = options.currentPageIndex, onCurrentPageIndexChange = options.onCurrentPageIndexChange, total = options.total, perPage = options.perPage;
    var _a = (0, react_1.useState)(function () { return initCurrentPageIndex || 0; }), currentPageIndex = _a[0], setCurrentPageIndex = _a[1];
    var pageCount = (0, react_1.useMemo)(function () { return Math.round(total / perPage); }, [total, perPage]);
    (0, react_1.useEffect)(function () {
        if (typeof initCurrentPageIndex === 'number')
            setCurrentPageIndex(initCurrentPageIndex);
    }, [initCurrentPageIndex]);
    var setPage = (0, react_1.useCallback)(function (pageIndex) {
        if (onCurrentPageIndexChange)
            onCurrentPageIndexChange(pageIndex);
        setCurrentPageIndex(pageIndex);
    }, [onCurrentPageIndexChange]);
    var _b = (0, react_1.useState)((0, get_pagination_1.getPagination)({
        count: total,
        currentPageIndex: currentPageIndex,
        perPage: perPage,
        setCurrentPage: setPage,
    })), pagination = _b[0], setPagination = _b[1];
    (0, react_1.useEffect)(function () {
        var localPag = (0, get_pagination_1.getPagination)({
            count: total,
            currentPageIndex: currentPageIndex,
            perPage: perPage,
            setCurrentPage: setPage,
        });
        setPagination(localPag);
    }, [setPage, total, perPage, currentPageIndex]);
    var previousPage = (0, react_1.useCallback)(function () {
        setCurrentPageIndex(currentPageIndex - 1);
    }, [currentPageIndex]);
    var nextPage = (0, react_1.useCallback)(function () {
        setCurrentPageIndex(currentPageIndex + 1);
    }, [currentPageIndex]);
    return {
        currentPage: currentPageIndex + 1,
        currentPageIndex: currentPageIndex,
        total: total,
        pageCount: pageCount,
        perPage: perPage,
        skip: perPage * currentPageIndex,
        nextPage: nextPage,
        previousPage: previousPage,
        pagination: pagination,
    };
};
exports.usePagination = usePagination;
