import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'User:name',
    type: 'text',
  },
};

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'text',
  },
};

export const userInput = {
  name,
  email,
};
