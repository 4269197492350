import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const stripePriceId: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'stripePriceId',
    label: 'AdminOptions:stripePriceId',
    type: 'text',
  },
};

const archiveUser: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'archiveUser',
    label: 'AdminOptions:archiveUser',
    type: 'text',
  },
};

export const adminOptionsInput = {
  archiveUser,
  stripePriceId,
};
