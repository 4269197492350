import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'Voucher:name',
    type: 'text',
  },
};

const isActive: FormInput = {
  type: 'checkbox',
  initValue: true,
  validation: Yup.boolean().required(),
  props: {
    name: 'isActive',
    label: 'Voucher:isActive',
  },
};

const useLimit: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.number().required(),
  props: {
    name: 'useLimit',
    type: 'number',
    label: 'Voucher:useLimit',
  },
};

const endDate: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'endDate',
    type: 'date',
    label: 'Voucher:endDate',
  },
};

export const voucherInput = {
  name,
  isActive,
  useLimit,
  endDate,
};
