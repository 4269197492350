import { useRecoilState } from 'recoil';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { AppFormGenerator } from 'shared-ui/lib/customs/react/app-form-generator/AppFormGenerator';
import { VehicleFilterFormBody, vehicleFilterForm } from '../forms/vehicle-filter.form';
import { vehicleFilterAtom } from '../store/vehicle-filter.atom';

const Footer = () => {
  return <Button type="submit">Etsi</Button>;
};

export const VehicleFilter = (): JSX.Element => {
  const [vehicleFilter, setVehicleFilter] = useRecoilState(vehicleFilterAtom);

  const onSubmit = (data: VehicleFilterFormBody) => {
    setVehicleFilter(data);
  };

  return (
    <Card>
      <AppFormGenerator
        wrapperClassNames="space-y-4"
        initialValues={vehicleFilter}
        form={vehicleFilterForm}
        FooterElement={<Footer />}
        onSubmit={onSubmit}
      />
    </Card>
  );
};
