import { useRecoilValue } from 'recoil';
import { AppIndexScene } from 'shared-ui/lib/customs/react/app-index-scene/AppIndexScene';
import { Button } from 'shared-ui/lib/customs/react/buttons/Button';
import { userColumn } from '../../columns/user.column';
import { UserFilter } from '../../components/UserFilter';
import { useExportUsersLazyQuery, useUserIndexSceneLazyQuery } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';
import { userFilterAtom } from '../../store/user-filter.atom';

const Tool = () => {
  const [exportUsers, { loading }] = useExportUsersLazyQuery({
    onCompleted: (data) => {
      if (!data.exportCSV) return;
      const url = window.URL.createObjectURL(new Blob([data.exportCSV], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
    },
  });

  return (
    <Button type="button" loading={loading} className="float-right" onClick={() => exportUsers()}>
      Export
    </Button>
  );
};

export const UserIndexScene = (): JSX.Element => {
  const userFilter = useRecoilValue(userFilterAtom);
  const context = useGQLContext();
  const query = useUserIndexSceneLazyQuery({
    context,
  });

  return (
    <div className="grid grid-cols-4 space-x-4">
      <div className="col-span-1">
        <UserFilter />
      </div>
      <div className="col-span-3">
        <AppIndexScene
          tNs="UserIndexScene"
          lazyQuery={query}
          Tool={<Tool />}
          columns={[
            userColumn.id,
            userColumn.name,
            userColumn.email,
            userColumn.type,
            userColumn.partnerName,
            userColumn.businessName,
            userColumn.isOwner,
            userColumn.link,
          ]}
          dataPath="aUsers.list"
          totalCountPath="aUsers.totalCount"
          where={userFilter}
        />
      </div>
    </div>
  );
};
