import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { transferInput } from '../inputs/transfer.input';

const form: FormInput[] = [transferInput.userId];

export const transferConsumerForm = createForm(form);

export type TransferConsumerFormBody = {
  userId: string;
};
