import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppIndexScene } from 'shared-ui/lib/customs/react/app-index-scene/AppIndexScene';
import { voucherColumn } from '../../columns/voucher.column';
import { routesConfig } from '../../config/routes.config';
import { useVoucherIndexSceneLazyQuery } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const VoucherIndexScene = (): JSX.Element => {
  const { t } = useTranslation('VoucherIndexScene');
  const context = useGQLContext();
  const query = useVoucherIndexSceneLazyQuery({
    context,
  });

  const Tool = (
    <Link
      className="float-right py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
      to={routesConfig.VOUCHER.CREATE}
    >
      {t('create')}
    </Link>
  );

  return (
    <AppIndexScene
      tNs="VoucherIndexScene"
      lazyQuery={query}
      columns={[
        voucherColumn.name,
        voucherColumn.isActive,
        voucherColumn.useLimit,
        voucherColumn.uses,
        voucherColumn.endDate,
        voucherColumn.createdAt,
        voucherColumn.linkToVoucher,
      ]}
      dataPath="vouchers.list"
      totalCountPath="vouchers.totalCount"
      Tool={Tool}
    />
  );
};
