import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Card } from 'shared-ui/lib/components/Card';
import { CardTitle } from 'shared-ui/lib/components/CardTitle';
import { AppFormScene } from 'shared-ui/lib/customs/react/app-form-scene/AppFormScene';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { UserFormBody, userEditForm } from '../../forms/user.form';
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUserEditSceneQuery,
} from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const UserEditScene = (): JSX.Element => {
  const { t } = useTranslation('UserEditScene');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const context = useGQLContext();

  const query = useUserEditSceneQuery({ variables: { id }, context });

  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: (data) => {
      if (data.aUpdateUser) {
        toast.success(t('Common:saved'));
        navigate(routesConfig.USER.INDEX);
      }
    },
    context,
  });

  const [deleteUser] = useDeleteUserMutation({
    onCompleted: (data) => {
      if (data.aDeleteUser) {
        toast.success(t('Common:deleted'));
        navigate(routesConfig.USER.INDEX);
      }
    },
    context,
  });

  const onDelete = () => {
    if (confirm('Are you sure?')) {
      deleteUser({ variables: { id } });
    }
  };

  const handleSubmit = (body: UserFormBody) => {
    updateUser({
      variables: {
        id,
        body: {
          name: body.name,
          email: body.email,
        },
      },
    });
  };

  return (
    <>
      <AppFormScene
        type="edit"
        query={query}
        queryDataPath="aUser"
        onDelete={onDelete}
        title={t('title')}
        form={userEditForm}
        isSubmitLoading={loading}
        onSubmit={handleSubmit}
        backLink={routesConfig.USER.INDEX}
      />
      <div className="mt-8">
        <div className="mx-auto w-full max-w-3xl sm:px-2">
          <Card>
            <CardTitle>Info</CardTitle>
            <p>Stripe: {query.data?.aUser.stripeCustomerId ?? '-'}</p>
            <p>Business ID: {query.data?.aUser.business?.id ?? '-'}</p>
            <p>Business Stripe: {query.data?.aUser.business?.stripeCustomerId ?? '-'}</p>
            <p>Yrityksen omistaja: {query.data?.aUser.isBusinessAccountOwner ? 'Kyllä' : 'Ei'}</p>
          </Card>
        </div>
      </div>
    </>
  );
};
